.intro {
  display: flex;
  height: 100vh;
  flex-direction: column;
  // border-top: 12px solid blue;

  .i_left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .i_left_wrapper {
      padding: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-left: 50px;

      .i_intro {
        font-size: 20px;
        font-weight: 300px;
      }

      .i_name {
        font-size: 40px;
      }

      .i_title {
        height: 50px;
        overflow: hidden;

        .i_title_wrapper {
          // height: 100%;
          animation: move 5s ease-in-out infinite alternate;

          @keyframes move {
            50% {
              transform: translateY(-50px);
            }
            100% {
              transform: translateY(-100px);
            }
          }

          .i_title_item {
            height: 50px;
            font-size: 25px;
            font-weight: bold;
            color: #59b246;
          }
        }
      }
      
      .i_description {
        font-size: 15px;
        font-weight: bold;
        // visibility: hidden;
      }
    }

    .i_scroll {
      visibility: hidden;
    }
  }

  .i_right {
    flex: 1;
    position: relative;

    .i_bg {
      clip-path: polygon(
        100% 0%,
        100% 52%,
        100% 100%,
        25% 100%,
        0% 50%,
        25% 0%
      );
      background-color: #59b246;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }

    .i_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }
}

@media only screen and (min-width: 700px) {
  .intro {
    flex-direction: row;

    .i_left {
      .i_left_wrapper {
        padding: 50px;
        height: 50%;
        //   background-color: red;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .i_intro {
          font-size: 30px;
          font-weight: 300px;
        }

        .i_name {
          font-size: 60px;
        }

        .i_title {
          .i_title_wrapper {
            .i_title_item {
              font-size: 35px;
              overflow: hidden;
            }
          }
        }
        .i_description {
          font-weight: bold;
          font-size: 25px;

          // visibility: visible;
        }
      }

      .i_scroll {
        visibility: visible;
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 20px;
      }
    }
  }
}
