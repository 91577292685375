.contact {
  height: 100vh;
  position: relative;

  .c_bg {
    width: 20px;
    height: 100%;
    background-color: #59b256;
    position: absolute;
  }

  .c_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 50px;

    .c_left {
      flex: 1;

      .c_title {
        font-size: 30px;
        text-align: center;
      }

      .c_info {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 20px 0px;

        .c_info_item {
          font-size: 60px;
          & a,
          a:visited {
            color: green;
          }
          & a:hover,
          a.active {
            color: red;
          }
        }
      }
    }

    .c_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .c_description {
        font-weight: 200;
        margin-left: 15px;
      }

      form {
        margin-top: 0px;
        display: flex;
        flex-wrap: wrap;

        input {
          width: 35%;
          height: 40px;
          border: none;
          border-bottom: 1px solid black;
          margin: 10px 0px;
          font-size: 14px;
          padding-left: 10px;
          margin: 5px 0px 2px;
          margin-left: 0px;
        }

        textarea {
          width: 100%;
          height: 60px;
          margin: 10px 0px;
          font-size: 14px;
          padding-left: 10px;
        }

        button {
          border: none;
          padding: 10px;
          background-color: #59b256;
          color: white;
          font-weight: 200;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .contact {
    margin-top: 100px;

    .c_wrapper {
      padding: 50px;
      flex-direction: row;

      .c_left {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .c_title {
          font-size: 50px;
        }
        .c_info {
          margin: 20px 0px;

          .c_info_item {
            font-size: 80px;
          }
        }
      }

      .c_right {
        form {
          margin-top: 20px;

          input {
            width: 50%;
            height: 50px;
            margin: 10px 0px;
          }

          textarea {
            height: 100px;
          }

          button {
            padding: 15px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
