.about {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  .a_left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .a_card {
      height: 150px;
      width: 250px;
      margin-top: 15px;

      .a_img {
        border-radius: 30px;
        // filter: drop-shadow(30px 10px 4px #333);
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .a_right {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // margin-left: 15px;

    .a_title {
      font-weight: bold;
    }

    .a_sub {
      margin: 15px 0px;
      font-size: 18px;
    }

    .a_description {
      font-weight: 300;
      margin: 10px 10px;      
    }

    .a_language {
      margin: 0px 20px;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 700px) {
  .about {
    flex-direction: row;

    .a_left {
      .a_card {
        height: 500px;
        width: 300px;

        .a_img {
          filter: drop-shadow(10px 10px 4px #333);
        }
      }
    }

    .a_right {
      margin-right: 15px;

      .a_title {
        font-size: 40px;
      }

      .a_sub {
        font-size: 25px;
      }

      .a_description {
        font-size: 20px;
      }

      .a_language {
        margin: 20px 0px;
        font-size: 25px;
      }
    }
  }
}
